@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
@import "../../node_modules/aos/dist/aos.css";

@import "fonts.css";

* {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
}

html {
  scroll-behavior: smooth;
}

.transperant-bg-dark {
  position: relative;
  background-color: rgba(0, 0, 0, 0.75);
  min-height: 100%;
}

#bg-video {
  position: absolute;
  z-index: -10;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.text-primary {
  color: #ffc107 !important;
}

.text-white {
  color: #fff;
}

.btn-green {
  color: white;
  background-color: #128c7e;
  border-color: #017c6e;
}

.btn-green:hover {
  opacity: 0.75;
}

.btn-yellow {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-yellow:hover {
  color: #fff;
  background-color: #d3a006;
  border-color: #ffc107;
}

.scroll-top {
  position: fixed;
  bottom: 15;
  right: 15;
  z-index: 10;
  font-size: 18px;
  border-radius: 100px;
}

.da-section {
  padding: 10% 0;
}

#contact {
  font-family: Arial, Helvetica, sans-serif;
  padding: 3% 0;
}

.da-project-1 {
  background-image: url("../assets/images/content/img1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.da-project-2 {
  background-image: url("../assets/images/content/img2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

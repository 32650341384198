@font-face {
  font-family: OpenSans;
  src: url(../assets/fonts/Open_Sans/OpenSans-Regular.ttf) format("truetype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: OpenSans;
  src: url(../assets/fonts/Open_Sans/OpenSans-Bold.ttf) format("truetype");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url(../assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url(../assets/fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
  font-weight: normal;
  font-display: swap;
}
